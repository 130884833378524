import '../sass/home-header.scss';

(() => {
    const $homeHeaders = document.querySelectorAll('.home-header');

    if (!$homeHeaders) return;

    $homeHeaders.forEach($homeHeader => {
        const $countdown = $homeHeader.querySelector('.home-header__countdown');
        const $countdownHrs = $homeHeader.querySelector('.home-header__countdown-hrs');
        const $countdownMins = $homeHeader.querySelector('.home-header__countdown-mins');
        const $countdownSecs = $homeHeader.querySelector('.home-header__countdown-secs');

        const initCountdown = () => {
            if (!$countdown) return;

            const biddingClose = new Date(parseInt($countdown.dataset.biddingClose) * 1000);

            setInterval(() => {
                const now = new Date();

                const diff = new Date(biddingClose.getTime() - now.getTime());

                if ($countdownHrs.innerHTML != diff.getUTCHours()) {
                    $countdownHrs.innerHTML = diff.getUTCHours();
                }

                if ($countdownMins.innerHTML != diff.getUTCMinutes()) {
                    $countdownMins.innerHTML = diff.getUTCMinutes();
                }

                if ($countdownSecs.innerHTML != diff.getUTCSeconds()) {
                    $countdownSecs.innerHTML = diff.getUTCSeconds();
                }
            }, 1000);
        };

        initCountdown();
    });
})();
